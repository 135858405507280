import L from "leaflet";

export const getLKtileLayers = (): L.TileLayer[] => {
  return [
    L.tileLayer.wms("https://karta3.ledningskollen.se/geoserver/wms/", {
      layers: "ledningskollen:fastighetskartan_nedtonad",
      format: "image/png",
      maxZoom: 5,
      minZoom: 0,
      attribution: "none",
    })
  ]
};