import { groupBy } from 'lodash';
import {fetchSettings, fetchStats} from 'api/municipalstatsapi';
import {MunicipalStatsDict} from 'types/municipalstats';
import {MunicipalPercentages} from 'types/municipalpercentages';
import {CountyPercentageDict, CountyPercentages} from 'types/countypercentages';
import { PercentageTotals } from 'types/percentages';
import { Settings } from 'types/settings';


export async function getPercentages(): Promise<PercentageTotals> {
  const settings: Settings = await fetchSettings();
  let statistics: MunicipalStatsDict = await fetchStats(settings.municipalstatsJsonUrl);

  statistics = Object.fromEntries(
    Object.entries(statistics).map(([key, val]) => [key, {...val, Kommunkod: key}])
  );

  let percentages = Object.fromEntries(
    Object.entries(statistics).map(([key, val]) => [key, new MunicipalPercentages(key, val)])
  );

  let totals = getTotals(statistics);

  return {percentages, totals};
}

function getTotals(statistics: MunicipalStatsDict): CountyPercentageDict {
  const groupedCounties = groupBy(Object.values(statistics), (val) => {
    return getCountyCode(val.Kommunkod);
  });

  let countiesPercentage = Object.keys(groupedCounties).map((key) => {
    return new CountyPercentages(groupedCounties[key]);
  });

  let countyTotals = Object.fromEntries(
    countiesPercentage.map((c) => {
      return [c.Länskod, c];
    })
  );

  return countyTotals;
}

export function getCountyCode(municipalityCode: string) {
  return municipalityCode.slice(0, municipalityCode.length - 2).padStart(2, '0');
}



