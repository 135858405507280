import { PercentValue } from 'types/percentages';

export function formatPercent(value: number | null): string {
  return value !== null ? `${formatIntPercent(value)}` : '-';
}

export function formatPercentValue(percentValue: PercentValue): string {
  return percentValue.percent !== null
    ? `${formatIntPercent(percentValue.percent)} ${percentValue.included} av ${percentValue.total}`
    : '-';
}

export function formatOriginalValues(percentValue: PercentValue): string {
  return percentValue.percent !== null
    ? `${percentValue.included} av ${percentValue.total}`
    : '';
}

const formatIntPercent = (value: number): string => `${value.toFixed(0)}%`;
