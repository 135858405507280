import { useState } from 'react';
import './App.css';
import { Outlet, useNavigate } from 'react-router-dom';
import InfoPopUp from './components/infopopup';
import { ReactComponent as InfoIcon } from './assets/infoicon.svg';
import { ReactComponent as HomeIcon } from './assets/homeicon.svg';

function App() {
  const [visibility, setVisibility] = useState(true);
  let navigate = useNavigate();

  const popupCloseHandler = () => {
    setVisibility(false);
  };

  const onHomeIconClick = () => {
    navigate('/', { replace: true, state: { selectedCode: null } });
  };

  const onInfoIconClick = () => {
    setVisibility(true);
  };

  return (
    <div className="app">
      <header className="app-header">
        <div className="header-icon-container">
          <HomeIcon onClick={onHomeIconClick} className="header-icon" title="Hem" />
          <InfoIcon onClick={onInfoIconClick} className="header-icon" title="Information" />
        </div>
        <h1>Kommunal anslutning till Ledningskollen</h1>
        <div className="layout-fix"></div>
      </header>
      <Outlet />
      <InfoPopUp visible={visibility} onClose={popupCloseHandler} />
    </div>
  );
}

export default App;
