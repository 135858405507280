import React from 'react';
import './infopopup.css';
import PropTypes from 'prop-types';
import { getColor } from 'components/map/map';

interface IInfoPopUp {
  visible: boolean;
  onClose(): void;
}

const InfoPopUp: React.FC<IInfoPopUp> = ({ onClose, visible }) => {
  function closeHandler(): void {
    onClose();
  }

  const allNetsStyle = {
    backgroundColor: getColor(1),
  };

  const someNetsStyle = {
    backgroundColor: getColor(0.5),
  };

  const noNetsStyle = {
    backgroundColor: getColor(0),
  };

  return (
    <div
      style={{
        visibility: visible ? 'visible' : 'hidden',
        opacity: visible ? '1' : '0',
      }}
      className="overlay"
    >
      <div className="popup-container">
        <span className="close" onClick={closeHandler}>
          &times;
        </span>
        <div className="popup-wrapper">
          <h1 className="title">Kommunal anslutning till Ledningskollen</h1>
          <div className="content">
            <p className="content-intro">
              Kartan visar vilka kommunala ledningar som är med i Ledningskollen, uppdelat per kommun. Fem typer av
              infrastruktur visas i kartan: El, VA, fjärrvärme, publika fibernät och gatubelysning.
            </p>
            <p>
              Underlaget har sammanställts av ledningsägarnas angivna information i Ledningskollen ("Typ av
              infrastruktur"), information på kommunala webbplatser samt, i vissa fall, telefonsamtal med
              ledningsägarna. Kommunala ledningar=ägda av kommunen, hel- eller delägda kommunala bolag eller
              kommunalförbund.
            </p>
            <p className="contact">
              Har du hittat ett fel? Vänligen meddela{' '}
              <a href="mailto:support@ledningskollen.se">support@ledningskollen.se</a>.
            </p>
            <h2>Tolka kartan</h2>
            <p>
              <span className="strong" style={allNetsStyle}>
                Grönt:
              </span>{' '}
              Alla kommunala ledningstyper är med i Ledningskollen
              <br />
              <span className="strong" style={someNetsStyle}>
                Gult:
              </span>{' '}
              En eller flera (men inte alla) kommunala ledningstyper är med i Ledningskollen
              <br />
              <span className="strong" style={noNetsStyle}>
                Rött:
              </span>{' '}
              Inga kommunala ledningstyper är med i Ledningskollen
            </p>
            <p>Sätt muspekaren över en kommun på kartan för att se mer information.</p>
            <h2>Tolka tabellen (på kommunnivå)</h2>
            <p>
              <span className="strong">100%</span> = Ledningstypen är med i Ledningskollen
              <br />
              <span className="strong">50%</span> = Kommunen äger flera ledningsnät av samma typ och hälften är med i
              Ledningskollen.
              <br />
              <span className="strong">0%</span> = Ledningstypen är inte med i Ledningskollen
              <br />
              <span className="strong">Tomt</span> = Ledningstypen finns inte alls i kommunen eller finns men ägs inte
              kommunen, t.ex. är det vanligt att kommersiella elbolag äger elnätet i en kommun.
            </p>
            <h2>Hjälp</h2>
            <p>
              Har du markerat en kommun och vill tillbaka till startläget? Klicka på kommunen i tabellen för att
              avmarkera den, använd Hem-knappen eller ladda om webbsidan.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

InfoPopUp.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default InfoPopUp;
