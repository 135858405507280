
export async function fetchSettings(): Promise<any> {
  return await fetchJSON('/data/settings.json');
}

export async function fetchStats(url: RequestInfo): Promise<any> {
  return await fetchJSON(url);
}

export async function fetchMunicipals(): Promise<any> {
  return await fetchJSON('/data/municipals.geo.json');
}

async function fetchJSON(url: RequestInfo): Promise<any> {
  try {
    const response = await fetch(url);
    return await response.json();
  } catch (err) {
    console.error(err);
  }
}

