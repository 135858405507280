import React from "react";
import { MunicipalPercentageDict } from "types/municipalpercentages";
import TableRow from "components/tablecontainer/tablerow";
import "./tablecontainer.css";
import { CountyPercentageDict, CountyPercentages } from "types/countypercentages";

type AppProps = {
  statistics: MunicipalPercentageDict;
  totals: CountyPercentageDict;
  selectedCode: number | null;
  setSelected(code: number): void;
};

const TableContainer: React.FC<AppProps> = ({ statistics, totals, selectedCode, setSelected }: AppProps) => {

  function getTableHeaders(): React.ReactNode {
    return (
      <div key={0} className={"grid table-header"}>
        <div className={"item-1"}>Län</div>
        <div className={"item-2"}>Kommun</div>
        <div className={"item-3"} title="El">El</div>
        <div className={"item-4"} title="Vatten och avlopp">VA</div>
        <div className={"item-5"} title="Fjärrvärme">FV</div>
        <div className={"item-6"} title="Publika fibernät">Fiber</div>
        <div className={"item-7"} title="Gatubelysning">GB</div>
      </div>
    );
  }

  function renderTable(statistics: MunicipalPercentageDict, totals: CountyPercentageDict): React.ReactNode {
    return (
      <div className="table-content">
        {Object.entries(totals)
          .sort((a, b) => {
            return a[1].Län.localeCompare(b[1].Län, 'sv');
          })
          .map(([key, value]) => {
            return renderCountyMunicipalities(key, value, statistics);
          })}
      </div>
    );
  }

  function renderCountyMunicipalities(
    countyCode: string,
    total: CountyPercentages,
    statistics: MunicipalPercentageDict
  ): React.ReactNode {
    return (
      <div key={countyCode}>
        <TableRow key={countyCode} id={countyCode} row={total} selected={selectedCode} setSelected={setSelected}></TableRow>
        {renderMunicipalitiesByCounty(countyCode, statistics)}
      </div>
    );
  }

  function renderMunicipalitiesByCounty(countyCode: string, statistics: MunicipalPercentageDict): React.ReactNode {
    const asArray = Object.entries(statistics);
    const municipalities = asArray
      .filter(([key, value]) => value.getCountyCode() === countyCode)
      .sort((a, b) => {
        return a[1].Kommun.localeCompare(b[1].Kommun, 'sv');
      });

    return (
      <div>
        {municipalities.map(([key, value]) => {
          return <TableRow key={key} id={key} row={value} selected={selectedCode} setSelected={setSelected}></TableRow>;
        })}
      </div>
    );
  }

  return (
    <div className={"table-container"}>
      {getTableHeaders()}
      <div className={"table-wrapper"}>
        {renderTable(statistics, totals)}
      </div>
    </div>
  );
};

export default TableContainer;
