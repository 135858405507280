import React, { useState, useEffect, useCallback } from 'react';
import { Params, useParams, useNavigate, useLocation } from 'react-router-dom';
import { getPercentages } from 'utils/percentageUtil';
import TableContainer from 'components/tablecontainer/tablecontainer';
import MapContainer from './map/mapcontainer/mapcontainer';
import './main.css';


const Main: React.FC = () => {

  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const code: number | null = parseMunicipalityCode(params);

  const [statistics, setStatistics] = useState({});
  const [totals, setTotals] = useState({});
  const [selectedCode, setSelectedCode] = useState<number | null>(code);
  const [calculatedHeight, setCalculatedHeight] = useState('');

  function parseMunicipalityCode(params: Readonly<Params<string>>): number | null {
    if (!params.code) return null;

    const parsedCode = parseInt(params.code);
    if (isNaN(parsedCode)) {
      console.log(`"${params.code}" är inte en giltig kommunkod eller länskod`);
      return null;
    }
    return parsedCode;
  }

  const locationState: any = location.state;
  const navigatedHome = locationState && locationState.selectedCode === null;
  

  function handleSelectCode(code: number): void {
    const newCode = code === selectedCode ? null : code;
    setSelectedCode(newCode);
    navigate(getTargetResource(newCode), { replace: true })
  }

  function getTargetResource(code: number | null): string {
    if (code === null) return '/';

    const isMunicipality = code >= 100;
    return isMunicipality ? 
      `/kommun/${code.toString().padStart(4, '0')}` : 
      `/lan/${code.toString().padStart(2, '0')}`;
  }

  function handleResize() {
    const nav = document.getElementsByClassName('app-header')[0];
    if (!nav) return;
    const h = nav.getBoundingClientRect().height;
    const wh = window.innerHeight;
    setCalculatedHeight(wh - h + 'px');
  }

  const getData = useCallback(async () => {
    let { percentages, totals } = await getPercentages();

    setStatistics(percentages);
    setTotals(totals);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      //Cleanup
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={'main-container'} style={{ height: `${calculatedHeight}` }}>
      <TableContainer
        statistics={statistics}
        totals={totals}
        selectedCode={navigatedHome ? null : selectedCode}
        setSelected={handleSelectCode}
      ></TableContainer>
      <MapContainer
        statistics={statistics}
        selectedCode={navigatedHome ? null : selectedCode}
        setSelected={handleSelectCode}
      ></MapContainer>
    </div>
  );
};

export default Main;
