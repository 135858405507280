import L, { LeafletMouseEventHandlerFn } from 'leaflet';
import { fetchMunicipals } from 'api/municipalstatsapi';
import { getColor } from 'components/map/map';
import { MunicipalPercentageDict, MunicipalPercentages } from 'types/municipalpercentages';
import { FeatureMetadata } from 'types/featuremetadata';
import { FeatureCollection } from 'geojson';
import { getCountyCode } from 'utils/percentageUtil';

export async function getMunicipalsGeoJsonLayer(
  statistics: MunicipalPercentageDict,
  handleClick: LeafletMouseEventHandlerFn
): Promise<L.GeoJSON<FeatureCollection>> {

  function onEachFeature(feature: any, layer: L.Layer) {
    layer.bindTooltip(getMunicipalityInfo(feature.properties));
    layer.on({
        //mouseover: highlightFeature,
        //mouseout: resetHighlight,
        click: handleClick
    });
  }

  const municipals = await fetchMunicipals();
  applyStatisticsToFeatures(municipals, statistics);
  let geoJsonLayer = L.geoJSON(municipals, { style: municipalStyle, onEachFeature: onEachFeature });
  return geoJsonLayer;
}

function applyStatisticsToFeatures(municipals: any, statistics: MunicipalPercentageDict) {
  municipals.features.forEach((feature: any) => {
    const municipalityCode = parseInt(feature.properties.KnKod);
    const countyCode = parseInt(getCountyCode(feature.properties.KnKod));
    let kommun: MunicipalPercentages = statistics[municipalityCode];

    let metadata: FeatureMetadata = {} as FeatureMetadata;
    metadata.MunicipalityName = kommun.Kommun;
    metadata.MunicipalityCode = municipalityCode;
    metadata.CountyCode = countyCode;
    metadata.El = kommun.El;
    metadata.VA = kommun.VA;
    metadata.FV = kommun.FV;
    metadata.Ekom = kommun.Ekom;
    metadata.GB = kommun.GB;
    metadata.TotalCompletion = kommun.Total;

    feature.properties = metadata;
  });
}

function getMunicipalityInfo(metadata: FeatureMetadata): string {

  function toPercentRow(label: string, value: number | null): string {
    return value !== null ? `<div class="percentRow"><span>${label}</span><span>${value?.toFixed(0)}%</span></div>` : '';
  }

  const htmlString: string = `<h3>${metadata.MunicipalityName}</h3>
        <div class="totalPercentRow"><span>Totalt:</span><span>${Math.floor(metadata.TotalCompletion * 100)}%</span></div>
        ${toPercentRow('El', metadata.El.percent)}
        ${toPercentRow('Vatten och avlopp', metadata.VA.percent)}
        ${toPercentRow('Fjärrvärme', metadata.FV.percent)}
        ${toPercentRow('Fiber', metadata.Ekom.percent)}
        ${toPercentRow('Gatubelysning', metadata.GB.percent)}`;
  return htmlString;
}

export const highlightedFeatureStyle: L.PathOptions = {
  weight: 4,
  color: '#666',
  opacity: 1,
  fillOpacity: 0.7,
};

export const dimmedFeatureStyle: L.PathOptions = {
  weight: 2,
  color: 'black',
  dashArray: '3',
  opacity: 0.1,
  fillOpacity: 0.2,
};

function municipalStyle(feature: any) {
  const featureProperties: FeatureMetadata = feature.properties;
  return {
    fillColor: getColor(featureProperties.TotalCompletion),
    weight: 2,
    color: 'black',
    dashArray: '3',
    opacity: 0.1,
    fillOpacity: 0.7,
  };
}

