import * as L from 'leaflet';
import { CompletionGrade } from 'types/completiongrade';
import { FeatureMetadata } from 'types/featuremetadata';

export function getCrsSWEREF99TM(): L.CRS {
  //@ts-ignore
  return new L.Proj.CRS('EPSG:3006', '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs', {
    resolutions: [2048, 1024, 512, 256, 128, 64],
    origin: [0, 0],
  });
}

export function getColor(completion: number): string {
  return completion >= 1 ? '#01A209' : completion > 0 ? '#FFE000' : '#DD0000';
}

export function createLegend(options: L.ControlOptions) {
  let legend = new L.Control(options);
  legend.onAdd = onAddLegend;
  return legend;
}

export function getFeatures(code: number, layers: L.Layer[]): L.FeatureGroup {
  return getFilteredFeatures(code, layers, metadataEquals);
}

export function getOtherFeatures(code: number, layers: L.Layer[]): L.FeatureGroup {
  return getFilteredFeatures(code, layers, metadataNotEquals);
}

function getFilteredFeatures(code: number, layers: L.Layer[], fn: IMetadataComparer): L.FeatureGroup {
  const features = layers.filter((l) => {
    let featuremetadata = ((l as L.GeoJSON<GeoJSON.GeoJsonObject>).feature as GeoJSON.Feature)
      .properties as FeatureMetadata;
    return fn(code, featuremetadata);
  });

  if (!features || features.length === 0) {
    const invalidCodeMsg = `"${code}" är inte en giltig kommunkod eller länskod`;
    throw new Error(invalidCodeMsg);
  }

  return new L.FeatureGroup(features);
}

interface IMetadataComparer {
  (code: number, metadata: FeatureMetadata): boolean;
}

interface IMetadataEquality {
  (metadataCode: number, code: number): boolean;
}

function codeEquals(metadataCode: number, code: number): boolean {
  return metadataCode === code;
}

function codeNotEquals(metadataCode: number, code: number): boolean {
  return metadataCode !== code;
}

function metadataCompare(code: number, metadata: FeatureMetadata, fn: IMetadataEquality): boolean {
  const isMunicipality = code >= 100;
  return isMunicipality ? fn(metadata.MunicipalityCode, code) : fn(metadata.CountyCode, code);
}

function metadataEquals(code: number, metadata: FeatureMetadata): boolean {
  return metadataCompare(code, metadata, codeEquals);
}

function metadataNotEquals(code: number, metadata: FeatureMetadata): boolean {
  return metadataCompare(code, metadata, codeNotEquals);
}

const grades: CompletionGrade[] = [
  { grade: 1, label: 'Alla nät' },
  { grade: 0.5, label: 'Ett eller flera nät' },
  { grade: 0, label: 'Inga nät' },
];

function onAddLegend(map: any) {
  let div = L.DomUtil.create('div', 'info');

  div.innerHTML += '<p class="legend-header">Anslutningsgrad i Ledningskollen</p>';

  // loop through our intervals and generate a label with a colored square for each interval
  for (var i = 0; i < grades.length; i++) {
    div.innerHTML += `<div class="legend"><i style="background: ${getColor(grades[i].grade)} "></i><span>${grades[i].label}</span></div>`;
  }

  return div;
}
