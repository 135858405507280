import { MunicipalStatsRow } from "./municipalstats";
import { Percentages, PercentValue } from 'types/percentages'


export class CountyPercentages implements Percentages {
  Län: string;
  Länskod: string;
  VA: PercentValue;
  El: PercentValue;
  FV: PercentValue;
  Ekom: PercentValue;
  GB: PercentValue;

  constructor(dtos: MunicipalStatsRow[]) {
    this.Län = dtos[0].Län;
    this.Länskod = this.getCountyCode(dtos[0].Kommunkod);
    this.VA = this.toPercentValue(dtos, 'VAMed', 'VATotal');
    this.El = this.toPercentValue(dtos, 'ElMed', 'ElTotal');
    this.FV = this.toPercentValue(dtos, 'FVMed', 'FVTotal');
    this.Ekom = this.toPercentValue(dtos, 'EkomMed', 'EkomTotal');
    this.GB = this.toPercentValue(dtos, 'GBMed', 'GBTotal');
  }

  toPercentValue(
    dtos: MunicipalStatsRow[],
    included: keyof MunicipalStatsRow,
    total: keyof MunicipalStatsRow
  ): PercentValue {
    const sumIncluded = this.sumProperty(dtos, included);
    const sumTotal = this.sumProperty(dtos, total);

    return {
      percent: this.calculatePercentage(sumIncluded, sumTotal),
      included: sumIncluded,
      total: sumTotal,
    };
  }

  sumProperty(dtos: MunicipalStatsRow[], propName: keyof MunicipalStatsRow): number {
    return dtos.reduce((previousValue, currentValue) => previousValue + Number(currentValue[propName]), 0);
  }

  calculatePercentage(included: number, total: number): number | null {
    if (total === 0 || total == null) return null;
    return (included / total) * 100;
  }

  getCountyCode(municipalityCode: string) {
    return municipalityCode.slice(0, municipalityCode.length - 2).padStart(2, '0');
  }
}


export interface CountyPercentageDict {
  [id: string]: CountyPercentages;
}
