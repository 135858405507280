import { MunicipalStatsRow, MunicipalInfo } from "./municipalstats";
import { Percentages, PercentValue } from 'types/percentages'

export class MunicipalPercentages implements MunicipalInfo, Percentages {
  Kommunkod: string;
  Kommun: string;
  Län: string;
  Region: string;
  VA: PercentValue;
  El: PercentValue;
  FV: PercentValue;
  Ekom: PercentValue;
  GB: PercentValue;
  Total: number;

  constructor(kommunkod: string, dto: MunicipalStatsRow) {
    this.Kommunkod = kommunkod.padStart(4, '0');
    this.Kommun = dto.Kommun;
    this.Län = dto.Län;
    this.Region = dto.Region;
    this.VA = this.toPercentValue(dto.VAMed, dto.VATotal);
    this.El = this.toPercentValue(dto.ElMed, dto.ElTotal);
    this.FV = this.toPercentValue(dto.FVMed, dto.FVTotal);
    this.Ekom = this.toPercentValue(dto.EkomMed, dto.EkomTotal);
    this.GB = this.toPercentValue(dto.GBMed, dto.GBTotal);
    this.Total = this.calculateTotal(dto);
  }

  toPercentValue(included: number, total: number): PercentValue {
    return {
      percent: this.calculatePercentage(included, total),
      included,
      total,
    };
  }

  calculatePercentage(included: number, total: number): number | null {
    if (total === 0 || total == null) return null;
    return (included / total) * 100;
  }

  getCountyCode() {
    return this.Kommunkod.slice(0, this.Kommunkod.length - 2).padStart(2, '0');
  }

  calculateTotal(dto: MunicipalStatsRow): number {
    let sumIncluded = dto.VAMed + dto.ElMed + dto.FVMed + dto.EkomMed + dto.GBMed;
    let sumTotal = dto.VATotal + dto.ElTotal + dto.FVTotal + dto.EkomTotal + dto.GBTotal;

    return sumTotal === 0 ? 0 : sumIncluded/sumTotal;
  }
}


export interface MunicipalPercentageDict {
  [id: string]: MunicipalPercentages;
}
