import React, { useCallback, useEffect, useRef } from 'react';
import * as L from 'leaflet';
import 'proj4leaflet';
import 'leaflet/dist/leaflet.css';
import { MunicipalPercentageDict } from 'types/municipalpercentages';
import { getLKtileLayers } from 'components/map/layers/tilelayers';
import { dimmedFeatureStyle, getMunicipalsGeoJsonLayer, highlightedFeatureStyle } from 'components/map/layers/geoJson';
import { createLegend, getCrsSWEREF99TM, getFeatures, getOtherFeatures } from 'components/map/map';
import './mapcontainer.css';
import { FeatureMetadata } from 'types/featuremetadata';
import { FeatureCollection } from 'geojson';

const initialMapCenter = new L.LatLng(61.1704, 18.27662);
const initialMapZoomLevel = 1;
const panDurationInSeconds = 0.25;

interface AppProps {
  statistics: MunicipalPercentageDict;
  selectedCode: number | null;
  setSelected(municipalityCode: number): void;
}

const MapContainer: React.FC<AppProps> = ({ statistics, selectedCode, setSelected }: AppProps) => {
  let map = useRef<L.Map | null>(null);
  var municipalsLayer = useRef<L.GeoJSON<FeatureCollection> | null>(null);

  const isEmpty = (statistics: {}) => {
    return Object.keys(statistics).length === 0;
  };

  const createMap = useCallback(
    async (statistics: MunicipalPercentageDict) => {
      const handleClick: L.LeafletMouseEventHandlerFn = (e: L.LeafletMouseEvent): void => {
        const featureMetaData: FeatureMetadata = e.target.feature.properties;
        setSelected(featureMetaData.MunicipalityCode);
      };

      if (map.current === null && !isEmpty(statistics)) {
        map.current = new L.Map('map', {
          crs: getCrsSWEREF99TM(),
          center: initialMapCenter,
          zoomControl: false,
          zoom: initialMapZoomLevel,
          layers: getLKtileLayers(),
        });

        municipalsLayer.current = await getMunicipalsGeoJsonLayer(statistics, handleClick);
        map.current.addLayer(municipalsLayer.current);

        L.control.zoom({ position: 'topleft' }).addTo(map.current);
        L.control.scale({ position: 'bottomleft' }).addTo(map.current);

        const legend = createLegend({ position: 'bottomright' });
        legend.addTo(map.current);

        map.current.attributionControl.removeAttribution('none').addAttribution('Kartdata © 2021 Lantmäteriet');
      }
    },
    [setSelected]
  );

  const dimmOtherFeatures = useCallback((code: number, layers: L.Layer[]) => {
    let otherFeatures: L.FeatureGroup = getOtherFeatures(code, layers);
    otherFeatures.setStyle(dimmedFeatureStyle);
  }, []);

  const zoomToFeatures = useCallback((code: number, layers: L.Layer[]) => {
    let layerFeatures: L.FeatureGroup = getFeatures(code, layers);
    layerFeatures.setStyle(highlightedFeatureStyle);
    map.current?.flyToBounds(layerFeatures.getBounds(), {
      duration: panDurationInSeconds,
    });
  }, []);

  const highlightFeature = useCallback(
    (code: number) => {
      if (municipalsLayer.current !== null) {
        const layers = municipalsLayer.current?.getLayers() || [];
        try {
          zoomToFeatures(code, layers);
          dimmOtherFeatures(code, layers);
        } catch (error: any) {
          console.log(error.message);
        }
      }
    },
    [zoomToFeatures, dimmOtherFeatures]
  );

  const resetMap = useCallback(() => {
    if (municipalsLayer.current !== null) {
      municipalsLayer.current.resetStyle();
      map.current?.setView(initialMapCenter, initialMapZoomLevel);
    }
  }, []);

  useEffect(() => {
    createMap(statistics).then(() => {
      if (selectedCode !== null) highlightFeature(selectedCode);
      else resetMap();
    });

    return () => {
      //cleanup
    };
  }, [createMap, statistics, highlightFeature, selectedCode, resetMap]);

  return (
    <div className={'map-container'}>
      <div id="map"></div>
    </div>
  );
};

export default MapContainer;
