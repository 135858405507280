import React, { MouseEventHandler, useCallback, useEffect } from 'react';
import { CountyPercentages } from 'types/countypercentages';
import { MunicipalPercentages } from 'types/municipalpercentages';
import { formatPercent, formatOriginalValues } from './tablerowformatter';
import './tablecontainer.css';
import { useIntersectionObserver } from '@uidotdev/usehooks';

type AppProps = {
  id: string;
  row: MunicipalPercentages | CountyPercentages;
  selected: number | null;
  setSelected(municipalityCode: number): void;
};

const TableRow: React.FC<AppProps> = ({ row, id, selected, setSelected }) => {
  const [tableRowRef, tableRowEntry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });

  const [countyRowRef, countyRowEntry] = useIntersectionObserver({
    threshold: 0,
    root: null,
    rootMargin: '0px',
  });

  const handleClick: MouseEventHandler = (event) => {
    const code = parseInt(event.currentTarget.id);
    setSelected(code);
  };

  const hasSelection = selected !== null;
  const isSelected = hasSelection && parseInt(id) === selected;

  const scrollInto = useCallback(async () => {
    if (isSelected) {
      if (!tableRowEntry?.isIntersecting) tableRowEntry?.target?.scrollIntoView({ behavior: 'smooth' });
      if (!countyRowEntry?.isIntersecting) countyRowEntry?.target?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [
    countyRowEntry?.isIntersecting,
    countyRowEntry?.target,
    isSelected,
    tableRowEntry?.isIntersecting,
    tableRowEntry?.target,
  ]);

  useEffect(() => {
    scrollInto();

    return () => {
      //clean up
    };
  }, [scrollInto]);

  function renderRow() {
    return row instanceof CountyPercentages
      ? renderCountyRow(row as CountyPercentages)
      : renderMunicipalityRow(row as MunicipalPercentages);
  }

  return renderRow();

  function renderCountyRow(row: CountyPercentages) {
    return (
      <div
        ref={countyRowRef}
        key={id}
        id={id.toString()}
        className={`grid county-header ${isSelected && 'table-row-selected'}`}
        onClick={handleClick}
      >
        <div className={'item-1'} title={`Länskod: ${row.Länskod}`}>
          {row.Län}
        </div>
        <div className={'item-2'}></div>
        <div className={'item-3'} title={formatOriginalValues(row.El)}>
          {formatPercent(row.El.percent)}
        </div>
        <div className={'item-4'} title={formatOriginalValues(row.VA)}>
          {formatPercent(row.VA.percent)}
        </div>
        <div className={'item-5'} title={formatOriginalValues(row.FV)}>
          {formatPercent(row.FV.percent)}
        </div>
        <div className={'item-6'} title={formatOriginalValues(row.Ekom)}>
          {formatPercent(row.Ekom.percent)}
        </div>
        <div className={'item-7'} title={formatOriginalValues(row.GB)}>
          {formatPercent(row.GB.percent)}
        </div>
      </div>
    );
  }

  function renderMunicipalityRow(row: MunicipalPercentages) {
    return (
      <div
        ref={tableRowRef}
        key={id}
        id={id.toString()}
        className={`grid table-row ${isSelected && 'table-row-selected'}`}
        onClick={handleClick}
      >
        <div className={'item-1'}></div>
        <div className={'item-2'} title={`Kommunkod: ${row.Kommunkod}`}>
          {row.Kommun}
        </div>
        <div className={'item-3'} title={formatOriginalValues(row.El)}>
          {formatPercent(row.El.percent)}
        </div>
        <div className={'item-4'} title={formatOriginalValues(row.VA)}>
          {formatPercent(row.VA.percent)}
        </div>
        <div className={'item-5'} title={formatOriginalValues(row.FV)}>
          {formatPercent(row.FV.percent)}
        </div>
        <div className={'item-6'} title={formatOriginalValues(row.Ekom)}>
          {formatPercent(row.Ekom.percent)}
        </div>
        <div className={'item-7'} title={formatOriginalValues(row.GB)}>
          {formatPercent(row.GB.percent)}
        </div>
      </div>
    );
  }
};

export default TableRow;
